import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Com from '../../../assets/images/svg/com.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CiCircleQuestion } from 'react-icons/ci';
import ProgressBar from 'react-bootstrap/ProgressBar';

const Dashboard = (props) => {
    const [key, setKey] = useState('dashboard');
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);
    const userToken = localStorage.getItem("userToken");
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Current and overdue amount, which you will receive every day or in months.
        </Tooltip>
    );

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}dashboard`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`, // Replace with your actual access token
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setDashboardData(data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []); // The empty dependency array ensures that this effect runs once after the initial render

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-right">
            <div className='companyheadr'>
                <div className='companydetails'>
                    <div className='icon'>
                        <img src={Com} alt="Company Icon" />
                    </div>
                    <div className='headingname'>
                        <h1>Hello, {dashboardData.user.fullName}</h1>
                        <p>{dashboardData.user.orgName}</p>
                    </div>
                </div>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="dashboard" title="Dashboard">
                    <div className='biiling-inshights'>
                        <p>
                            Total Receivables
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <span id="total"> <CiCircleQuestion /></span>
                            </OverlayTrigger>
                            <ProgressBar animated now={dashboardData.todaysTotalRevenue} id="progress" />
                        </p>

                        <div className='bill-inshights-tabs first-tab'>
                            <h2>₹ {dashboardData.todaysTotalRevenue.toFixed(2)}</h2>
                            <p id="total">Total Revenue</p>
                        </div>
                        <div className='bill-inshights-tabs'>
                            <h2>₹{dashboardData.todaysTotalRevenue.toFixed(2)}</h2>
                            <p id="due">Today's Revenue</p>
                        </div>
                        <div className='bill-inshights-tabs'>
                            <h2>{dashboardData.sevenDaysInvoiceCount.toFixed(2)}</h2>
                            <p>7 Days Invoices</p>
                        </div>
                        <div className='bill-inshights-tabs'>
                            <h2>₹ {dashboardData.quarterTotalRevenue.toFixed(2)}</h2>
                            <p>1st Quarter Revenue</p>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="explore" title="Getting Started"> 
                 <div className='getting-start'>
                    <div className='head-title'>
                        <h2>Welcome to florosense billing system</h2>
                        <p>The easy-to-use accounting software that you can set up in no time!</p>
                    </div>
                    <div className='demo'>
                        <div className='demo-video'>
                        <iframe src="https://www.youtube.com/embed/uaYDSZxlRkM?si=jrtwnmJlofcBIlFl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div className='demo-title'>
                            <h2>Getting Started with florosense billing system!</h2>
                            <p>Watch this short overview video to get a head-start on everything florosense Billing has to offer to manage your business finance</p>
                        </div>

                    </div>
                 </div>
                </Tab>
                {/* <Tab eventKey="updates" title="Recent Update"> </Tab>
                <Tab eventKey="announcements" title="Announcements"> </Tab>
                <Tab eventKey="pricing" title="Pricing"> </Tab> */}
                <Tab eventKey="apps" title="Apps"> </Tab>
                <Tab eventKey="templates" title="Templates"> </Tab>
            </Tabs>
        </div>
    );
};

export default Dashboard;
