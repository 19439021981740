import { BsThreeDots } from 'react-icons/bs';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import InvoiceNumberComponent from '../Format';

const Dashboard = (props) => {
  props.setShow(true);
  const user = props.user;
  const [filter, setFilter] = useState({ filter: '', asc: "no" });
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [downloadAllDisabled, setDownloadAllDisabled] = useState(true);

  const [editInvoiceDetail, setEditInvoiceDetail] = useState(false);

  const handleDelete = (invoiceId) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}invoice/${invoiceId}`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status) {

          Swal.fire({
            icon: "success",
            title: "Invoice Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }

      })
      .catch((error) => {

      });
  }



  const handleView = (invoiceId) => {
    const url = `/invoice/${invoiceId}/view`;
    window.open(url, '_blank');
  }

  const handleAddInvoice = () => {
    navigate("/create-invoice/new")
  };

  const handleSendToCa = (invoice) => {
    invoice.vendorIds.push(user?.caId);
    let data = JSON.stringify({
      sendToCa: true,
      vendorIds: invoice.vendorIds
    });
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `https://billapi.multigrad.in/auth/v1/invoice/${invoice._id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      data: data
    }
    axios.request(config)
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Invoice Sent to CA Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }

      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });

  }

  useEffect(() => {
    handleFilter();
  }, [])


  const handleFilter = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      };

      const response = await axios.get(`${process.env.REACT_APP_HOST}filterinvoices?filter=${filter.filter}&asc=${filter.asc}`, config);
      setInvoices(response.data.invoices);
    } catch (error) {
      setInvoices([]);
    }
  };






  return (
    <div className="container-right">



      <div className="container mt-5 mt-0 d-flex flex-column">
        <div className='filter'  >
          <select onChange={(e) => setFilter({ ...filter, filter: e.target.value })}>
            <option value={'todays'}>Today's</option>
            <option value={'Last3months'}>Last 3 months</option>
            <option value={'Last6months'}>Last 6 months</option>
            <option value={'Last12months'}>Last 12 months</option>
            <option value={'Draft'}>Draft</option>

          </select>

          <select onChange={(e) => setFilter({ ...filter, asc: e.target.value })}>
            <option value={'yes'}>Ascending</option>
            <option value={'no'}>Descending</option>
          </select>
          <button type='button' onClick={handleFilter}>Search</button>
        </div>
        <div className="d-flex justify-content-end">


          <button className="btn btn-dark new" onClick={handleAddInvoice}>
            + New
          </button>
        </div>



        <div className="container mt-4">

        </div>
        <table className="table table-striped">
          {/* Table headers */}
          <thead>
            <tr>
              <th scope="col">
                <td><input type="checkbox" /></td>
              </th>
              <th scope="col">Date</th>
              <th scope="col">Invoice#</th>
              <th scope="col">Organisation Name</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Manage</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr className={index % 2 === 0 ? "bg-light" : "bg-secondary text-black"}>
                <td><input type="checkbox" /></td>
                <td>{moment(invoice.createdDate).format('DD-MM-YYYY')}</td>
                <td> <InvoiceNumberComponent page={'INV'} billNumber={invoice.billNumber} /></td>

                <td>{invoice.orgName}</td>
                <td>{invoice.invoiceStatus}</td>
                <td>₹ {invoice.totalINR.toFixed(0)}</td>


                <td>
                  <DropdownButton id="dropdown-item-button btn-dark" variant='dark' title={<BsThreeDots size={26} />}>
                    {/*<button disabled={invoice.invoiceStatus !== 'complete'} onClick={() => user?.caId && handleSendToCa(invoice)} className='btn btn-secondary' >{invoice.sendToCa ? "Sent" : "Send"}</button>
                */}<button type='button' onClick={() => handleView(invoice._id)} className="btn btn-secondary">
                      <FaCloudDownloadAlt size={26} color="white" />
                    </button>
                    <Dropdown.Item as="button" onClick={() => handleView(invoice._id)}>View</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => navigate(`/invoice/edit/${invoice._id}`)} >Edit</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleDelete(invoice._id)}>Delete</Dropdown.Item>

                  </DropdownButton>
                </td>
              </tr>
            ))}


          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;

